import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { faAdd, faSearch, faTrash, faEllipsisV, faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import DataWrapper from "../../DataWrapper";
import InputField from "../../../../../../components/form/InputField";
import TableStd from '../../../../../../components/ui/TableStd';
import DropdownU from '../../../../../../components/ui/DropdownU';
import Filter from "./Filter";
import PaginationU from '../../../../../../components/ui/PaginationU';
import LimitU from '../../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../../components/ui/DataNotFound';
import ButtonU from "../../../../../../components/ui/ButtonU";
import ToastU from "../../../../../../components/ui/ToastU";
import SortU from "../../../../../../components/ui/SortU";
import Create from "./Create";
import Update from "./Update";
import Delete from "./Delete";
import { useTranslation } from "react-i18next";

const Warehouses = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);

    const [warehouses, setWarehouses] = useState([]);
    const [warehousesCount, setWarehousesCount] = useState();
    const [departments, setDepartments] = useState([]);

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['order', 'ASC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');

    const [modalCreate, setModalCreate] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    
    const [itemToBe, setItemToBe] = useState({});

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('SETTINGS'), link: '/settings/profile'}, { title: t('DATABASE'), link: '/settings/data/general'}, { title: t('WAREHOUSE'), link: '/settings/data/warehouse'}, { title: t('WAREHOUSES'), active: true }
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getWarehouses();
            getDepartments();
        }
    }, [ready, query, toast]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }

    const getWarehouses = () => {
        setLoading(true);
        axiosPrivate.get('/warehouses', { params: query }).then((res) => {
            if (res.status == 200) {
                setWarehouses(res.data.data);
                setWarehousesCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    const getDepartments = () => {
        setLoading(true);
        axiosPrivate.get('/departments').then((res) => {
            if (res.status == 200) setDepartments(res.data.data);
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery({...query, filter: { name: find }});
    }, [find]);
 
    const createNew = () => { setModalCreate(true); }

    const refresh = () => { getWarehouses() }
    
    const modelProps = [
        { 
            name: <SortU title={t('NAME')} attr={'name'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.name}</>) } 
        },
        { 
            name: <SortU title={t('CODE')} attr={'code'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.code}</>) } 
        },
        {
            name: t('DEPARTMENT'),
            value: (prop) => { return(<>{prop.department.name}</>) } 
        },
        {
            name: <SortU title={t('ORDER')} attr={'order'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.order}</>) } 
        },
        {
            name: "",
            value: (prop) => {
                const options = [
                    {
                        name: t('EDIT'),
                        icon: faPenToSquare,
                        color: "text-dark",
                        onClick: () => {
                            setItemToBe(prop);
                            setModalUpdate(true);
                        }
                    },
                    {
                        name: t('DELETE'),
                        icon: faTrash,
                        color: "text-danger",
                        onClick: () => { 
                            setItemToBe(prop);
                            setModalDelete(true);
                        }
                    }
                ]
                return (
                    <DropdownU className='d-flex align-items-center justify-content-center' toggleClass={"card-8-outline py-1 px-1"} icon={faEllipsisV} items={options}/>
                )
            }
        }
    ];

    return (
        <DataWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="bold fs-5 fg-dark">{t('WAREHOUSES')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={t('FIND')} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    <ButtonU onSubmit={createNew} className={'border-success fg-green-500'} icon={faAdd} text={t('ADD')}/>
                </div>
            </div>
            { warehousesCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={warehouses} count={warehousesCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={warehousesCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            <Create
                modalShow={modalCreate}
                setModalShow={setModalCreate}
                toast={toast}
                setToast={setToast}
                departments={departments}
                setLoading={setLoading}
            />
            <Update
                modalShow={modalUpdate}
                setModalShow={setModalUpdate}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
                departments={departments}
                setLoading={setLoading}
            />
            <Delete
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
                setLoading={setLoading}
            />
            <ToastU toast={toast} setToast={setToast}/>
            <div className="mb-5"></div>
        </DataWrapper>
    );
}

export default Warehouses;