export default class Keys {
    // static USERNAME_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{5,31}$/;
    static USERNAME_REGEX = /^[a-zA-Z][a-zA-Z0-9-_.]{3,31}$/;
    // static EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    static EMAIL_REGEX = /^[a-zA-Z][a-zA-Z0-9-_.]{3,31}$/;
    static OTP_REGEX = /^[0-9]{2,5}$/;
    // static PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&]).{8,255}$/;
    static PASSWORD_REGEX = /^[ -~]{3,255}$/;
    static NAME_REGEX = /^[\p{L}\p{N}\p{M}&.\s-]{3,255}$/u;
    static PHONE_NUMBER_REGEX = /^[6-7][1-5][0-9]{6}$/; // starts with 6, second digit in range(1-5), then any number
    static UNSIGNED_DOUBLE_REGEX = /^[0-9.]{0,14}$/;
    static UNSIGNED_INTEGER_REGEX = /^[0-9]{0,100}$/;
    static PHONE_CODE = "+993";

    static UNAUTHORIZED = "Unauthorized";
    static TMT = "TMT";

    // For App passwords / Roles
    static ADMINISTRATION = "Administration"; // Administration

    static ROOT = "ROOT"; // Root
    static ADMIN = "ADMIN"; // Admin
    static MNGR = "MNGR"; // Manager
    static WH = "WH"; // Warehouse

    // ---------------------- LINKS ------------------------------------
    // Links Main
    static DASHBOARD_LINKS = [ '/' ];

    static PRODUCT_CATALOG_PRODUCTS_LINKS = [ '/product-catalog/:id/products', '/product-catalog/:id/products/:id', '/product-catalog/:id/products/create' ];
    static PRODUCT_CATALOG_LINKS = this.PRODUCT_CATALOG_PRODUCTS_LINKS.concat();

    static INVOICE_INVOICES_LINKS = [ '/invoice/:id/invoices', '/invoice/:id/invoices/:id', '/invoice/:id/invoices/create' ];
    static INVOICE_LINKS = this.INVOICE_INVOICES_LINKS.concat();
    
    static WAREHOUSE_INCOMES_LINKS = [ '/warehouse/incomes' ];
    static WAREHOUSE_EXPENSES_LINKS = [ '/warehouse/expenses' ];
    static WAREHOUSE_REQUEST_FORMS_LINKS = [ '/warehouse/request-forms', '/warehouse/request-forms/:id', '/warehouse/request-forms/create', '/warehouse/request-forms/update/:id' ];
    static WAREHOUSE_WAREHOUSES_LINKS = [ '/warehouse/warehouses' ];
    static WAREHOUSE_LINKS = this.WAREHOUSE_INCOMES_LINKS.concat(this.WAREHOUSE_EXPENSES_LINKS, this.WAREHOUSE_REQUEST_FORMS_LINKS, this.WAREHOUSE_WAREHOUSES_LINKS);

    static COMPANY_SUPPLIERS_LINKS = [ '/company/suppliers' ];
    static COMPANY_CONTACTS_LINKS = [ '/company/contacts' ];
    static COMPANY_LINKS = this.COMPANY_SUPPLIERS_LINKS.concat(this.COMPANY_SUPPLIERS_LINKS, this.COMPANY_CONTACTS_LINKS);

    static REPORTS_GENERAL_LINKS = [ '/reports/general', '/reports/general/report' ];
    static REPORTS_MONTHLY_REPORTS_LINKS = [ '/reports/monthly-reports', '/reports/monthly-reports/:id' ];
    static REPORTS_LINKS = this.REPORTS_GENERAL_LINKS.concat(this.REPORTS_MONTHLY_REPORTS_LINKS);

    // Links Settings -------------------------------------------

    static SETTINGS_LINKS = [
        '/settings/profile',
        '/settings/privacy-and-security',
        '/settings/data',
    ];

    static SETTINGS_DATA_GENERAL_LINKS = [
        '/settings/data/general',
        '/settings/data/general/product-classifications',
        '/settings/data/general/categories',
        '/settings/data/general/units',
        '/settings/data/general/brands',
        '/settings/data/general/manufacturers',
        '/settings/data/general/countries',
        '/settings/data/general/provinces',
        '/settings/data/general/districts'
    ];

    static SETTINGS_DATA_WAREHOUSE_LINKS = [
        '/settings/data/warehouse',
        '/settings/data/warehouse/departments',
        '/settings/data/warehouse/warehouses',
    ];

    static SETTINGS_DATA_ADMINISTRATION_LINKS = [
        '/settings/data/administration',
        '/settings/data/administration/users',
        '/settings/data/administration/users/create',
        '/settings/data/administration/users/:id',
        '/settings/data/administration/logs',
        '/settings/data/administration/roles',
        '/settings/data/administration/backup'
    ];

    static SETTINGS_DATA_LINKS = this.SETTINGS_DATA_GENERAL_LINKS.concat(this.SETTINGS_DATA_ADMINISTRATION_LINKS);
}