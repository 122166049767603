import { useEffect, useState } from "react";
import useAuth from "./../hooks/useAuth";
import UserAvatar from "../components/ui/UserAvatar";
import DropdownLang from "./DropdownLang";
import { Drawer } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BreadCrumbsU from "./../components/ui/BreadCrumbsU";
import icLogo from "../assets/svg/ic_logo.svg";
import icSettingsOutline from "../assets/svg/ic_settings_outline.svg";
import icWarehouseOutline from "../assets/svg/ic_warehouse_outline.svg";
import LinearIndeterminant from "../components/ui/PbLinearIndeterminant";
import ProgressBar from "../components/ui/ProgressBar";
import RightSideModal from "../components/ui/RightSideModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faCircleArrowLeft, faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import Utils from "../utils";
import { useTranslation } from "react-i18next";

const Header = ({...props}) => {
    const { t } = useTranslation();
    const { auth, isAdmin } = useAuth();
    const navigate = useNavigate();

    const [warehouse, setWarehouse] = useState('');

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (auth.user){
            setWarehouse(auth?.user?.warehouse?.name);
        }
    }, [auth]);

    const toggleDrawer = (flag) => () => {
        setOpen(flag)
    };

    return (
        <div className="header shadow-sm">
            <div className="d-flex flex-row align-items-center justify-content-between px-3">
                <div className="d-flex flex-row px-1 align-items-center">
                    <div className="d-flex flex-row align-items-center me-3">
                        <img src={icLogo} alt="logo" onClick={() => navigate('/')} className="wh-25 me-3 hover-cursor"/>
                        <div className="d-flex flex-row align-items-center me-1">
                            <span className="btn-back me-1" onClick={() => navigate(-1)}>
                                <FontAwesomeIcon icon={faCircleArrowLeft} className="me-2 font-14"/>
                                {t('BACK')}
                            </span>
                            <span className="btn-back" onClick={() => navigate(1)}>
                                {t('FORWARD')}
                                <FontAwesomeIcon icon={faCircleArrowRight} className="ms-2 font-14"/>
                            </span>
                        </div>
                        <BreadCrumbsU items={props.breadcrumbs} rootPageLink={props.rootPageLink} className={'ms-2 d-flex flex-row'}/>
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center">
                    {warehouse && <div className="d-flex flex-row align-items-center bg-green-700 py-1 px-1 rounded-2 me-3">
                        <img src={icWarehouseOutline} className="bg-green-50 rounded-1 p-1 wh-20 me-1"/>
                        <span className="medium font-13 text-white mx-1">{warehouse}</span>
                    </div>}
                    <DropdownLang/>
                    <div className="d-flex flex-row align-items-center">
                        <div className="d-flex flex-row align-items-center hover-cursor me-1"
                            onClick={toggleDrawer(true)}>
                            <UserAvatar user={auth?.user} style={'bg-blue-600 text-white wh-35'}/>
                            <FontAwesomeIcon icon={faAngleDown} className="small-x ms-2 fg-gray"/>
                        </div>
                        <div className="d-flex flex-column ms-2">
                            <span className="fg-dark font-14 medium">{Utils.capitalizeFirstLetter(auth?.user?.firstName)} {Utils.capitalizeFirstLetter(auth?.user?.lastName)}</span>
                            <span className="fg-gray font-12">{auth?.role?.name}</span>
                        </div>

                        {isAdmin && <span className="fg-gray-50 font-20 mx-3">|</span>}
                        {isAdmin && <div onClick={() => {navigate('/settings/data/general')}} className="hover-cursor">
                            <img src={icSettingsOutline} className="wh-35 p-2 bg-gray-50 rounded-2" />
                        </div>}
                    </div>
                </div>
            </div>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <RightSideModal onClose={toggleDrawer(false)}/>
            </Drawer>
            {props.loading && <LinearIndeterminant/>}
            {props.loading && <ProgressBar loading={props.loading}/>}
        </div>
    );
}

export default Header;