import { useState, useEffect } from "react";
import { Link, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { faSearch, faEllipsisV, faArrowRotateRight, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../utils";
import InvoiceWrapper from "../InvoiceWrapper";
import InputField from "../../../../../components/form/InputField";
import TableStd from '../../../../../components/ui/TableStd';
import DropdownU from '../../../../../components/ui/DropdownU'
import PaginationU from '../../../../../components/ui/PaginationU';
import LimitU from '../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../components/ui/DataNotFound';
import ButtonU from "../../../../../components/ui/ButtonU";
import SortU from '../../../../../components/ui/SortU';
import Filter from "./Filter";
import ToastU from "../../../../../components/ui/ToastU";
import ExportFile from "../components/ExportFile";
import ShowInvoiceItem from "../components/ShowInvoiceItem";
import { useTranslation } from "react-i18next";
import Keys from "../../../../../utils/constants";

const InvoiceItems = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const params = useParams();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);

    const [invoiceItems, setInvoiceItems] = useState([]);
    const [invoiceItemsCount, setInvoiceItemsCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['createdAt', 'DESC']],
        filter: { productClassificationId: params.pcid, createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');

    const [exportModal, setExportModal] = useState(false);
    const [modalShowItem, setModalShowItem] = useState(false);
    
    const [itemToBe, setItemToBe] = useState({});

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: '/' },
        { title: t('INVOICE'), link: `/invoice/${params.pcid}/invoices` } ,
        { title: t('PRODUCTS'), active: true}
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getInvoiceItems();
        }
    }, [ready, query]);
    
    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }
    
    const getInvoiceItems = () => {
        setLoading(true);
        axiosPrivate.get('/invoice-items', { params: query }).then((res) => {
            if (res.status == 200) {
                setInvoiceItems(res.data.data);
                setInvoiceItemsCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery({...query, filter: { ...query.filter, productName: find }});
    }, [find]);

    useEffect(() => {
        setQuery(prev => {
            return {...prev, filter: { ...prev.filter, productClassificationId: params.pcid }}
        });
    }, [params.pcid]);

    const refresh = () => { getInvoiceItems() }

    const modelProps = [ 
        {
            name: t('BAR_CODE'),
            value: (prop) => { return(<>{prop?.product?.barcode}</>) }
        },
        {
            name: t('PRODUCT'),
            value: (prop) => { return(<Link to={`/product-catalog/${params.pcid}/products/${prop?.product?.id}`} className="no-decor">{prop?.product?.name}</Link>) }
        },
        {
            name: `${t('INVOICE')} ${t('NUMBER')}`,
            value: (prop) => { return(<Link to={`/invoice/${params.pcid}/invoices/${prop?.invoice?.id}`} className='no-decor'>{prop?.invoice?.number}</Link>) } 
        },
        {
            name: <SortU title={t('QUANTITY')} attr={'quantity'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.quantity?.toFixed(2)} {prop?.product?.unit?.name}</>) }
        },
        {
            name: <SortU title={t('UNIT_PRICE')} attr={'unitPrice'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop?.unitPrice?.toFixed(2)} {Keys.TMT}</>) }
        },
        {
            name: t('TOTAL'),
            value: (prop) => { return(<>{prop?.totalUnitPrice} {Keys.TMT}</>) }
        },
        {
            name: <SortU title={t('REGISTRATION_DATE')} attr={'createdAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.createdAt)}</>) }
        },
        {
            name: "",
            value: (prop) => {
                const options = [
                    {
                        name: t('SHOW'),
                        icon: faEye,
                        color: "text-dark",
                        onClick: () => {
                            setItemToBe(prop);
                            setModalShowItem(true);
                        }
                    }
                ]
                return (
                    <DropdownU className='d-flex align-items-center justify-content-center' toggleClass={"card-8-outline py-1 px-1"} icon={faEllipsisV} items={options}/>
                )
            }
        }
    ];

    return (
        <InvoiceWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="fs-5 bold fg-dark">{t('PRODUCTS')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('PRODUCT')} ${t('NAME')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    <ButtonU onSubmit={() => {setExportModal(true);}} icon={faFileExport} text={t('EXPORT')} className={"bg-orange-400 bg-gradient text-white"}/>
                </div>
            </div>
            { invoiceItemsCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={invoiceItems} count={invoiceItemsCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={invoiceItemsCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            <ExportFile
                modalShow={exportModal}
                setModalShow={setExportModal}
                invoiceItemsCount={invoiceItemsCount}
                toast={toast}
                setToast={setToast}
            />
            <ShowInvoiceItem
                modalShow={modalShowItem}
                setModalShow={setModalShowItem}
                item={itemToBe}
            />
            <ToastU toast={toast} setToast={setToast}/>
        </InvoiceWrapper>
    );
}

export default InvoiceItems;