import { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { useNavigate } from "react-router-dom";
import { faAdd, faSearch, faEllipsisV, faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../../../../../utils";
import DataWrapper from "../../DataWrapper";
import InputField from "../../../../../../components/form/InputField";
import TableStd from '../../../../../../components/ui/TableStd';
import DropdownU from '../../../../../../components/ui/DropdownU';
import Filter from "./Filter";
import PaginationU from '../../../../../../components/ui/PaginationU';
import LimitU from '../../../../../../components/ui/LimitU';
import DataNotFound from '../../../../../../components/ui/DataNotFound';
import ButtonU from "../../../../../../components/ui/ButtonU";
import ToastU from "../../../../../../components/ui/ToastU";
import SortU from "../../../../../../components/ui/SortU";
import Status from "../../../../../../components/ui/Status";
import { useTranslation } from "react-i18next";

const Users = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState([]);
    const [usersCount, setUsersCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['createdAt', 'DESC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');
    
    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('SETTINGS'), link: '/settings/profile'}, { title: t('DATABASE'), link: '/settings/data/general'}, { title: t('ADMINISTRATION'), link: '/settings/data/administration' }, { title: t('USERS'), active: true }
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getUsers();
        }
    }, [ready, query, toast]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }

    const getUsers = () => {
        setLoading(true);
        axiosPrivate.get('/users', { params: query }).then((res) => {
            if (res.status == 200) {
                setUsers(res.data.data);
                setUsersCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery({...query, filter: { name: find }});
    }, [find]);
 
    const createNew = () => { navigate('/settings/data/administration/users/create')}

    const refresh = () => { getUsers() }
    
    const modelProps = [
        {
            name: <SortU title={t('USERNAME')} attr={'username'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<Link to={`/settings/data/administration/users/${prop.id}`} className="no-decor">{prop.username}</Link>) } 
        },
        { 
            name: <SortU title={t('FIRSTNAME')} attr={'firstName'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.firstName}</>) } 
        },
        {
            name: <SortU title={t('LASTNAME')} attr={'lastName'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.lastName}</>) } 
        },
        {
            name: <SortU title={t('MIDDLENAME')} attr={'middleName'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.middleName ? prop.middleName : '-'}</>) } 
        },
        {
            name: t('ROLE'),
            value: (prop) => { return(<>{prop.userAuth.role && prop.userAuth.role.name}</>) }
        },
        {
            name: t('VERIFIED'),
            value: (prop) => { return(<Status status={prop.userAuth.verified}/>) }
        },
        {
            name: t('ACTIVE'),
            value: (prop) => { return(<Status status={prop.userAuth.active}/>) }
        },
        {
            name: t('LAST_LOGIN'),
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.userAuth.lastLogin)}</>) }
        },
        {
            name: <SortU title={t('CREATED_DATE')} attr={'createdAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.createdAt)}</>) }
        },
        {
            name: "",
            value: (prop) => {
                const options = [
                    {
                        name: t('SHOW'),
                        icon: faEye,
                        color: "text-dark",
                        onClick: () => { navigate(`/settings/data/administration/users/${prop.id}`); }
                    }
                ]
                return (
                    <DropdownU className='d-flex align-items-center justify-content-center' toggleClass={"card-8-outline py-1 px-1"} icon={faEllipsisV} items={options}/>
                )
            }
        }
    ];

    return (
        <DataWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="bold fs-5 fg-dark">{t('USERS')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('NAME')}, ${t('LASTNAME')}, ${t('USERNAME')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    <ButtonU onSubmit={createNew} className={'border-success fg-green-500'} icon={faAdd} text={t('ADD')}/>
                </div>
            </div>
            { usersCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={users} count={usersCount} query={query}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={usersCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            <ToastU toast={toast} setToast={setToast}/>
            <div className="mb-5"></div>
        </DataWrapper>
    );
}

export default Users;