import DataWrapper from "../DataWrapper";
import Option from "./../../../components/Option";
import { useTranslation } from "react-i18next";
import icColorSwatch from "./../../../../../assets/svg/ic_color_swatch.svg";
import icLayer from "./../../../../../assets/svg/ic_layer_orange.svg";
import icRegulator from "./../../../../../assets/svg/ic_regulator.svg";
import icTag from "./../../../../../assets/svg/ic_tag_red.svg";
import icVerify from "./../../../../../assets/svg/ic_verify.svg";
import icMapBlue from "./../../../../../assets/svg/ic_map_blue.svg";
import icMapPinGreen from "./../../../../../assets/svg/ic_map_pin_green.svg";
import icMapPinLime from "./../../../../../assets/svg/ic_map_pin_lime.svg";

const General = () => {
    const { t } = useTranslation();

    const breadcrumbs = [
        { title: t('SETTINGS'), link: '/settings/profile'}, { title: t('DATABASE'), link: '/settings/data/general'}, { title: t('GENERAL'), active: true }
    ]

    const options = [
        { id: 'product_classifications', name: t('PRODUCT_CLASSIFICATIONS'), icon: icColorSwatch, link: '/settings/data/general/product-classifications'},
        { id: 'categories', name: t('CATEGORIES'), icon: icLayer, link: '/settings/data/general/categories'},
        { id: 'units', name: t('UNITS'), icon: icRegulator, link: '/settings/data/general/units'},
        { id: 'brands', name: t('BRANDS'), icon: icTag, link: '/settings/data/general/brands'},
        { id: 'manufacturers', name: t('MANUFACTURERS'), icon: icVerify, link: '/settings/data/general/manufacturers'},
        { id: 'countries', name: t('COUNTRIES'), icon: icMapBlue, link: '/settings/data/general/countries'},
        { id: 'provinces', name: t('PROVINCES'), icon: icMapPinGreen, link: '/settings/data/general/provinces'},
        { id: 'districts', name: t('DISTRICTS'), icon: icMapPinLime, link: '/settings/data/general/districts'},
    ]

    return (
        <DataWrapper breadcrumbs={breadcrumbs}>
            <div className="row p-3">
                { options.map((option) => {
                    return (<Option key={option.id} item={option}/>)
                })}
            </div>
        </DataWrapper>
    );
}

export default General;